<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        action-create
        disable-loading-on-refresh
        @refresh="getItems"
        @create="hRedirect('apps-targetgroups-new')"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage" />

      <b-overlay :show="!loaded" rounded="sm">
        <b-table
            ref="refTargetgroupsListTable"
            class="position-relative"
            responsive
            primary-key="id"
            :items="items"
            :per-page="perPage"
            :current-page="currentPage"
            :fields="tableColumns"
            :sort-by.sync="sortBy"
            :filter="filter.input"
            @filtered="onFiltered"
            show-empty
            :empty-text="$t('No matching records found')">

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button :to="{ name: 'apps-targetgroups-edit',
              params: { id: data.item.id },
            }"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="success"
                      type="submit"
                      size="sm"
            > {{ $t('Edit') }}
            </b-button>

            <b-button v-on:click="deleteItem(data.item.id)" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" type="submit" size="sm">
              {{ $t('Delete') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows" />
    </b-card-actions>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BOverlay
} from 'bootstrap-vue'
import {computed} from "@vue/composition-api";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    TableHeader,
    TableFooter,
    BCardActions,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        { key: 'name', sortable: true, label: this.$i18n.t('Name')},
        { key: 'status', sortable: true, label: this.$i18n.t('Status'),
          formatter: this.getStatusName
        },
        { key: 'actions', label: this.$i18n.t('Actions'), class: 'table-action-buttons' },
      ],
      items: [],
    }
  },
  computed: {},
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.helperGetItems(`/targetgroups`);
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/targetgroups/${id}`,
      );
    },
    getStatusName(value, key, item) {
      let status = [
        { value: 0, text: "Not Active" },
        { value: 1, text: "Active" },

      ];
      let StatusName;
      status.forEach(function (item) {
        if (item.value == value) status = item.text;
      });
      return this.$t(status)
    },
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
